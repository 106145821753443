<template>
	<div class="border-card">
		<el-tabs type="border-card">
			<el-tab-pane label="线路执行"><route-ShiftTotal></route-ShiftTotal></el-tab-pane>
			<el-tab-pane label="车辆执行"><vehicle-ShiftTotal></vehicle-ShiftTotal></el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import routeShiftTotal from "@/components/pages/admin/statisticalAnalysis/shiftTotal/routeShiftTotal";
import vehicleShiftTotal from "@/components/pages/admin/statisticalAnalysis/shiftTotal/vehicleShiftTotal";

export default {
	components: {
		routeShiftTotal,
		vehicleShiftTotal
	}
};
</script>
<style scoped>
.total {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	height: 54px;
	background: inherit;
	background-color: rgba(215, 215, 215, 1);
	padding: 20px;
	font-size: initial;
}
</style>
<style lang="scss">
.border-card {
	.el-tabs--border-card > .el-tabs__content {
		padding: 14px;
	}
}
</style>
